* {
}
p {
  font-size: 90%;
}
.mainContent,
.headerContent,
.footerContent {
  margin: 0 auto;
  width: 1280px;
}
footer {
  padding-top: 40px;
  background: #1127ff;
  color: #fff;
}
.box-container {
  display: flex;
}
.box-item {
  flex: 1 1 100%;
  padding: 18px;
}
a {
  color: #c44a67;
  text-decoration: none;
}
footer a {
  color: #fff;
  font-weight: bold;
}
.buttonMain {
  padding: 5px 10px !important;
}
.menuLink {
  text-decoration: none;
  color: #222;
}
.iconRight {
  margin-left: 10px;
}
.filterRow div {
  margin-bottom: 5px;
  margin-right: 5px;
}

.barIndicatorContainer {
  width: 203px;
  height: 20px;
  background-color: #ddd;
}
.negativeContainer {
  width: 100px;
  height: 20px;
  float: left;
}
.negativeBar {
  background-color: #ff2600;
  text-align: center;
  height: 20px;
  float: right;
  color: #fff;
  line-height: 20px;
}
.positiveContainer {
  width: 100px;
  height: 20px;
  float: right;
}
.positiveBar {
  background-color: #007eff;
  text-align: center;
  height: 20px;
  float: left;
  color: #fff;
  line-height: 20px;
}
.zeroBar {
  width: 3px;
  height: 20px;
  background-color: #333;
  float: left;
}
.reportTextField {
  width: 250px;
}

.ant-layout * {
  box-sizing: border-box;
}
.bwlWeo .ant-progress-show-info .ant-progress-outer {
  padding-right: 4em;
  margin-right: -4em;
}
.ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.ant-progress-inner {
  display: inline-block;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 100px;
  vertical-align: middle;
  position: relative;
}
.ant-progress-bg {
  background-color: #1890ff;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
  position: relative;
}
.ant-progress-status-active .ant-progress-bg:before {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  border-radius: 10px;
  -webkit-animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1)
    infinite;
  animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
}
.gaugeLabel {
  font-size: 70%;
  color: #333;
  text-align: center;
}
.noTopMargin {
  margin-block-start: 0;
}
.topMargin2 {
  margin-block-start: 2em;
}
.liquidGaugeContainer {
  margin-block-start: 2em;
  margin-block-end: 1em;
}
